import React from "react";
import Layout, { PageWrapper } from "../components/layout";
import { PageSection } from "../styles/index/_components";
// noinspection ES6UnusedImports
import "twin.macro";
import Terms from "../images/vectors/undraw_Terms_re_6ak4.svg";

const rules = {
  1: "Na wizytę można umówić się telefonicznie, poprzez adres e-mail lub formularz na stronie libere.pl.",
  2: "Wizyta musi zostać odwołana najpóźniej 24 godziny przed zaplanowaną sesją.",
  3: "W przypadku, gdy wizyta odwołana zostanie później niż 24 godziny przed zaplanowaną sesją – pacjent zobowiązany do wniesienia opłaty za sesję.",
  4: "W przypadku, gdy nie pojawią się Państwo na wizycie – również prosimy o wniesienie opłaty za sesję.",
  5: "Konsultacje trwają 50 minut i nie są przedłużane w przypadku spóźnień.",
  6: "Wizyty odwoływane przez specjalistę nie wiążą się z żadnymi kosztami pacjenta."
};

const Regulamin = () => {
  return (
    <Layout>
      <PageSection>
        <PageWrapper tw="relative text-center text-temp-300 py-20">
          <h3 tw="text-3xl py-10 z-10">Regulamin poradni</h3>
          {[...Array(6)].map((x, i) => {
            return (
              <div key={i} tw="py-3 text-left z-10">
                {(i += 1)}. {rules[i++]}
              </div>
            );
          })}
          <Terms tw="absolute right-0 h-2/6 w-2/6 bottom-0 z-0" />
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Regulamin;
